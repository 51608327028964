import { LOGIN_PATH } from "../configs/AppConfig";
import axios from "axios";
import decode from "jwt-decode";
import { AUTH_TOKEN } from "../redux/constants/Auth";
import { signInWithCustomToken } from "auth/FirebaseAuth";
import fetch from "../auth/FetchInterceptor";
import { message } from "antd";
import history from "history.js";
import { APP_PREFIX_PATH } from "configs/AppConfig";

const JwtAuthService = {};

JwtAuthService.login = function (data) {
  return axios.post(LOGIN_PATH, data, {
    headers: {
      "Content-Type": "application/json",
    },
  }).catch(error => {
    message.error('Login failed: ' + error.message);
    throw error;
  });
};

JwtAuthService.logout = function () {
  localStorage.clear();
};

JwtAuthService.isAuthenticated = function () {
  return !!localStorage.getItem(AUTH_TOKEN);
};

JwtAuthService.saveToken = async function (jwt) {
  localStorage.setItem(AUTH_TOKEN, jwt);
  const decodedToken = decode(jwt);

  if (decodedToken.firebase_auth_token) {
    signInWithCustomToken(decodedToken.firebase_auth_token);
  }

  const roles = decodedToken.roles;
  localStorage.setItem("username", decodedToken.username);
  
  const rolesWithLocalStorage = ["ADMIN", "COORDINATEUR", "COMPTABLE", "SECRETAIRE"];
  const rolesWithEnterpriseFetch = ["ENTERPRISE", "CASHIER"];

  for (let role of roles) {
    if (rolesWithLocalStorage.includes(role)) {
      localStorage.setItem("roles", JSON.stringify(roles));
      const url = window.location.href;

      if (url.includes("console")) {
        JwtAuthService.logout();
        message.error("Vous n'avez pas les droits pour accéder à cette page");
        history.push(`${APP_PREFIX_PATH}`);
      }
      return true;
    }

    if (rolesWithEnterpriseFetch.includes(role)) {
      localStorage.setItem("roles", JSON.stringify(roles));
      try {
        const data = await fetch.get("/auth/get-my-info");
        console.log("data", data);

        if (data && data.id) {
          localStorage.setItem("name", data.name);
          localStorage.setItem("enterpriseName", data.enterpriseName);

          if (role === "CASHIER") {
            localStorage.setItem("EnterpriseId", data.enterpriseId);
          } else if (role === "ENTERPRISE") {
            localStorage.setItem("EnterpriseId", data.id);
          }
        } else {
          console.error("No ID found in data");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        message.error("Error fetching enterprise data.");
      }
     

      return true;
    }
  }

  return false;
};

export default JwtAuthService;
