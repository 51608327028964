import React from "react";
import { Route, Switch, Redirect, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import AppLayout from "layouts/app-layout";
import AuthLayout from 'layouts/auth-layout';
import AppLocale from "lang";
import { IntlProvider } from "react-intl";
import { ConfigProvider } from 'antd';
import { APP_PREFIX_PATH, AUTH_PREFIX_PATH } from 'configs/AppConfig'
import JwtAuthService from "../services/JwtAuthService";

export const Views = (props) => {
    const { locale, location } = props;
    const currentAppLocale = AppLocale[locale];
    const isConnected = JwtAuthService.isAuthenticated();
    return (
        new Promise((resolve) => setTimeout(resolve, 100000)),
        <IntlProvider
            locale={currentAppLocale.locale}
            messages={currentAppLocale.messages}>
               
            <ConfigProvider locale={currentAppLocale.antd}>
                <Switch>
                    <Route exact path="/">
                        {
                            isConnected ? (<Redirect to={APP_PREFIX_PATH} />) : (<Redirect to={AUTH_PREFIX_PATH} />)
                        }

                    </Route>
                    <Route path={AUTH_PREFIX_PATH}>
                        {
                            isConnected ? (<Redirect to={APP_PREFIX_PATH} />) : (<AuthLayout />)
                        }

                    </Route>
                    <Route path={APP_PREFIX_PATH}>
                        {
                            isConnected ? (<AppLayout location={location} />) : (<Redirect to={AUTH_PREFIX_PATH} />)
                        }

                    </Route>

                </Switch>
            </ConfigProvider>
        </IntlProvider>
    )
}

const mapStateToProps = ({ theme, auth }) => {
    const { locale } = theme;
    const { token } = auth;
    return { locale, token }
};

export default withRouter(connect(mapStateToProps)(Views));