export const DEFAULT_PAGE_SIZE = 12;

export const DOCTOR_LIST_ENTRY_POINT = "/yata/doctors/";
export const DOCTORS_ACTIVITIES_TODAY = "/yata/doctor/activity/today";
export const ACTIVE_DOCTORS_ENTRY_POINT = "/yata/doctor/active/";
export const LIST_ACTIVE_DOCTORS_ENTRY_POINT =
  "/yata/doctor/activity/list-today";

export const PATIENT_LIST_ENTRY_POINT = "/yata/patients/";
export const HEALTH_PROFESSIONAL_LIST_ENTRY_POINT =
  "/yata/health-professionals/";

export const TYPICAL_CONSULTATION_RESPONSE = "/yata/typical-responses";
export const DOCTOR_BLOCK_ENTRY_POINT = "/yata/doctor/block/";
export const DOCTOR_UNBLOCK_ENTRY_POINT = "/yata/doctor/unblock/";
export const DOCTOR_DELETE_ENTRY_POINT = "/yata/doctor/delete/";
export const DOCTOR_SEND_SMS_ENTRY_POINT = "/yata/doctor/sms";

export const SPECIALITIES_LIST_ENTRY_POINT = "/yata/specialities/list/";
export const SEND_UNITES = "/yata/sendtransaction";
export const SEND_NOTIFICATION = "/coordinate/sharenotification";

export const TM_FORMS_LIST_ENTRY_POINT = "/yata/tm-forms/list/";
export const GALLERY = "/yata/gallery/";

export const LOGS_LIST_ENTRY_POINT = "yata/logs/list/";
export const ERRORS_LIST_ENTRY_POINT = "yata/logs/errors/";
export const CONSULTATION_LIST_ENTRY_POINT = "/coordinate/consultations/";
export const CONSULTATION_LIST_FILTER = "coordinate/consultations/filter";
export const ROOM_CONSULTATION_EXIST = "/coordinate/consultations/room-exist";
export const SEND_CONSULTATION_TO_EXPERT =
  "/coordinate/consultations/add-consultant/";
export const DELETE_RESPONSE = "/coordinate/consultations/delete-response/";
export const DELETE_IMAGE_CONSULTATION = "/yata/consultation/uploadimages/";
export const UPLOAD_IMAGE_CONSULTATION = "/yata/consultation/uploadimages/";

export const DELETE_CONSULTATION = "/yata/consultations/";

export const VALIDATE_RESPONSE = "/coordinate/consultations/validate-response/";
export const DELETE_AFFECTATION =
  "/coordinate/consultations/delete-consultant/";
export const GET_MEDECIN = "/yata/doctor/";
export const GET_REFUNDS = "yata/doctor/refunds/";
export const GET_ACTIVITIES = "yata/doctor/activity/get/";
export const GET_TRANSACTIONS = "yata/doctor/transactions/";
export const GET_IMAGE = "/yata/image-resized/";
export const UPDATE_IMAGE = "/yata/update-image/";
export const GET_ARTICLES = "/yata/articles/";
export const GET_PAYMENTS = "/yata/payments-list/";
export const UPLOAD_IMAGE_PROFILE_MEDECIN =
  "/yata/medecin/upload-image-profile/";
export const UPLOAD_IMAGE_CARTE_MEDECIN = "/yata/medecin/upload-carte-medecin/";

export const VALIDATE_ARTICLES = "/coordinate/article/valider";
export const REJECT_ARTICLES = "/yata/article/reject";
export const GET_CONSULTATIONS_RESPONSE = "/coordinate/consultations/";

//ENTERPRISES

export const ADD_ENTERPRISE = "/yata/enterprise/add";
export const EDIT_ENTERPRISE = "/yata/enterprise/update";
export const ENTERPRISES_LIST_ENTRY_POINT = "/yata/enterprise/list/";
export const ENTERPRISES_ALL_ENTRY_POINT = "/yata/enterprises";

export const ACTIVATE_ENTERPRISE = "/yata/enterprise/activate/";
export const GET_ENTERPRISE_REQUESTED_DOCTORS =
  "yata/enterprise/requested-doctors/";

export const DOCTORS_LIST_ENTRY_POINT = "/yata/doctors/";
export const DOCTOR_LIST_ENTRY_POINT_TEST = "/yata/doctors-test/";

//DICOMS
export const DICOMS_LIST_END_POINT = "/yata/folders";
export const GET_FOLDER = "/yata/folder/get/";
export const GET_TOKEN_FOR_PREVIEW_DICOM = "/yata/dicom/get-token/";
export const PREVIEW_IMAGE_DICOM = "/yata/dicom/preview-instance/";
export const DOWNLOAD_FOLDER_PDF = "/yata/folder/sheet/";

export const INIT_DOCTOR_BALANCE = "/yata/doctor/init-balance/";
//EPRESCRIPTIONS
export const MEDICS_LIST = "/yata/eprescription/medics/";
export const MEDICS_DELETE = "/yata/eprescription/medics/delete";

export const PRESCRIPTION_TYPICAL_LIST = "/yata/eprescription/typicals/";

export const PRESCRIPTION_LIST = "/yata/eprescription/prescriptions/";

export const DOWNLOAD_PRESCRIPTION = "/prescriptions/download-pdf/";
export const MEDICAL_EXAM_PATIENT_LIST = "/yata/medical-exams/exam-patients/";
export const MEDICAL_EXAM_PATIENT_FILTER =
  "/yata/medical-exams/exam-patients/filter";

export const MEDICAL_EXAM_INVOICE = "/exams/invoice/";
export const MEDICAL_EXAM_FILE = "/exams/pdf/";

export const MEDICAL_EXAM_SET_LIST = "/yata/medical-exams/exam-patients/";
export const MEDICAL_EXAM_UPDATE_STATUS = "/yata/medical-exams/exam-response/";

// export const LOGS_LIST_BY_LEVEL_ENTRY_POINT = 'yata/logs/get-all-by-level/';

export const ACCOUNT_CREATION_LIST = "/user-creation/request-list";
export const ACCOUNT_CREATION_SAVE = "/user-creation/requests";
export const ACCOUNT_CREATION_ARCHIVE = "/user-creation/archive/";

export const ACCOUNT_CREATION_MIGRATION = "/user-creation/migrate-request";
//images

export const MEDIA_API = "/api/image/";

//folder creation request
export const FolderCreationRequest = "/yata/folder-creation-requests/";

export const PATCHES = "yata/patches";
export const PATCHES_LINKED_PATIENTS = "yata/patches/linked-patients";

export const CONTRACT = "yata/patient/contract";

//Enable services for doctors
export const ENABLE_SERVICE = "/yata/v1/doctors";
export const ACTIVATE_SERVICE_QUERY_PARAM = `?value=${true}`;
export const DEACTIVATE_SERVICE_QUERY_PARAM = `?value=${false}`;
export const DOCTOR_ENTERPRISES = "/v2/yata/enterprise/doctor";
export const ACTION_ON_ENTERPRISES = "/v2/yata/enterprise";
export const SWITCH_ACTION = "switch-activation";

// Approve enterprise creation
export const APPROUVE_ENTERPRISE = "enterprise-integration-requests";
export const LIST_ENTERPRISES = "enterprise-integration-requests/";
export const ALL_ENTERPRISES = "yata/enterprise/list";

//Docteur Schedule
export const DOCTOR_SCHEDULE = "v2/yata/doctor-schedules";

//Appointments
export const ONLINE_APPOINTMENTS = "v2/appointment/online-appointments";
export const OFFICE_APPOINTMENTS = "v2/appointment/office-appointments";
export const CANCEL_APPOINTMENT = "v2/appointment";

//Invoices
export const LIST_INVOICES = "v2/yata/billing/invoices";
export const LIST_PAYMENTS = "v2/yata/billing/payments";
export const APPROVE_INVOICES = "v2/yata/billing/payments";
export const PAYMENT_METHODS = "v2/yata/payments-methods";

//homeCare API
export const HomeCareRequests =
  "patient-app/home-health/home-care-request-list";
export const updateHomeCareRequest = "v2/yata/home-health/";
export const HomeSamplingRequests = "patient-app/home-health/home-sampling";

export const LABORATORIES_ENDPOINT = "/v2/laboratory";
